export default function History({ data }) {
  return (
    <>
      {data.length > 0 && (
        <>
          <div className="comp_history">
            <details>
              <summary>Recent queries</summary>
              <table>
                <thead>
                  <tr>
                    <th>Query</th>
                    <th>ID</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td>{item.postalcode}</td>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </details>
          </div>
        </>
      )}
    </>
  );
}
