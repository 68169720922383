export default function UpdateTheme() {
  const theme = localStorage.getItem('theme');
  if (theme === undefined) return;

  const root = document.documentElement;
  if (theme === 'dark-green') {
    root.style.setProperty('--bg1', 'rgb(27, 27, 27)');
    root.style.setProperty('--bg2', 'rgb(42 74 47)');
    root.style.setProperty('--text1', 'rgb(221, 221, 221)');
    root.style.setProperty('--text2', 'rgb(221, 221, 221)');
    root.style.setProperty('--icon1', 'rgb(216, 140, 0)');
    root.style.setProperty('--ok', '#0ba309');
    root.style.setProperty('--error', 'rgb(255 57 57)');
    root.style.setProperty('--button', 'rgb(49 114 0)');
  } else if (theme === 'dark-gray') {
    root.style.setProperty('--bg1', 'rgb(27, 27, 27)');
    root.style.setProperty('--bg2', 'rgb(70, 70, 70)');
    root.style.setProperty('--text1', 'rgb(221, 221, 221)');
    root.style.setProperty('--text2', 'rgb(221, 221, 221)');
    root.style.setProperty('--icon1', 'rgb(216, 140, 0)');
    root.style.setProperty('--ok', '#0ba309');
    root.style.setProperty('--error', 'rgb(255 57 57)');
    root.style.setProperty('--button', 'rgb(49 114 0)');
  } else if (theme === 'light-green') {
    root.style.setProperty('--bg1', 'rgb(243, 243, 243)');
    root.style.setProperty('--bg2', 'rgb(71, 136, 41)');
    root.style.setProperty('--text1', 'rgb(230, 230, 230)');
    root.style.setProperty('--text2', 'black');
    root.style.setProperty('--icon1', 'rgb(255 165 0)');
    root.style.setProperty('--ok', '#41dd00');
    root.style.setProperty('--error', 'rgb(163 0 0)');
    root.style.setProperty('--button', 'rgb(211 156 0)');
  } else if (theme === 'light-gray') {
    root.style.setProperty('--bg1', 'rgb(243, 243, 243)');
    root.style.setProperty('--bg2', 'rgb(192, 192, 192)');
    root.style.setProperty('--text1', 'rgb(34, 34, 34)');
    root.style.setProperty('--text2', 'black');
    root.style.setProperty('--icon1', 'rgb(197 127 0)');
    root.style.setProperty('--ok', '#058904');
    root.style.setProperty('--error', 'rgb(209 0 0)');
    root.style.setProperty('--button', 'rgb(49 114 0)');
  }
}
