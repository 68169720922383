import { useState } from 'react';
import UpdateTheme from '../utils/UpdateTheme';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function Settings({ close }) {
  const [values, setValues] = useState(initialValues());
  const [theme, setTheme] = useState(initialTheme());

  function initialValues() {
    const linkVal = document.cookie
      .split('; ')
      .find((row) => row.startsWith('link='))
      ?.split('=')[1];
    const uidVal = document.cookie
      .split('; ')
      .find((row) => row.startsWith('uid='))
      ?.split('=')[1];
    const keyVal = document.cookie
      .split('; ')
      .find((row) => row.startsWith('key='))
      ?.split('=')[1];
    return {
      link: linkVal === undefined ? '' : linkVal,
      uid: uidVal === undefined ? '' : uidVal,
      key: keyVal === undefined ? '' : keyVal,
    };
  }

  function initialTheme() {
    const theme = localStorage.getItem('theme');
    return theme === undefined ? '' : theme;
  }

  function updateValue(event) {
    let date = new Date();
    date.setTime(date.getTime() + 1826 * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();

    document.cookie = `${event.target.name}=${event.target.value};secure;samesite=strict;${expires}`;
    if (event.target.name === 'link') setValues((prev) => ({ ...prev, link: event.target.value }));
    if (event.target.name === 'uid') setValues((prev) => ({ ...prev, uid: event.target.value }));
    if (event.target.name === 'key') setValues((prev) => ({ ...prev, key: event.target.value }));
  }

  function storeTheme(event) {
    localStorage.setItem('theme', event.target.getAttribute('themeval'));
    setTheme(event.target.getAttribute('themeval'));
    UpdateTheme();
  }

  return (
    <div className="view_settings">
      <div className="view_settings__closeicon" onClick={() => close()}>
        <AiOutlineCloseCircle />
      </div>
      <h2>Settings</h2>
      <label>
        API Link
        <input
          spellCheck="false"
          type="text"
          name="link"
          onChange={(e) => updateValue(e)}
          value={values.link}
        />
      </label>
      <label>
        Uid
        <input
          spellCheck="false"
          type="text"
          name="uid"
          onChange={(e) => updateValue(e)}
          value={values.uid}
        />
      </label>
      <label>
        Key
        <input
          spellCheck="false"
          type="text"
          name="key"
          onChange={(e) => updateValue(e)}
          value={values.key}
        />
      </label>
      <h2 style={{ marginTop: '1.8rem' }}>Theme</h2>
      <div className="view_settings__theme" themeval="dark-green" onClick={(e) => storeTheme(e)}>
        ⛺ Dark Green
        {(theme === 'dark-green' || theme === null) && (
          <div className="view_settings__theme__selected">selected</div>
        )}
      </div>
      <div className="view_settings__theme" themeval="dark-gray" onClick={(e) => storeTheme(e)}>
        Dark Gray
        {theme === 'dark-gray' && <div className="view_settings__theme__selected">selected</div>}
      </div>
      <div className="view_settings__theme" themeval="light-green" onClick={(e) => storeTheme(e)}>
        ☀️ Light Green
        {theme === 'light-green' && <div className="view_settings__theme__selected">selected</div>}
      </div>
      <div className="view_settings__theme" themeval="light-gray" onClick={(e) => storeTheme(e)}>
        Light Gray
        {theme === 'light-gray' && <div className="view_settings__theme__selected">selected</div>}
      </div>
    </div>
  );
}
