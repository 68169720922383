import Home from './views/Home';
import './assets/styles/main.css';
import UpdateTheme from './utils/UpdateTheme';
import { useEffect, useState } from 'react';
import Settings from './views/Settings';

function App() {
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    const url = document.cookie
      .split('; ')
      .find((row) => row.startsWith('link='))
      ?.split('=')[1];
    const uid = document.cookie
      .split('; ')
      .find((row) => row.startsWith('uid='))
      ?.split('=')[1];
    const key = document.cookie
      .split('; ')
      .find((row) => row.startsWith('key='))
      ?.split('=')[1];

    if (url === undefined || uid === undefined || key === undefined) {
      setSettingsOpen(true);
    } else if (url === '' || uid === '' || key === '') {
      setSettingsOpen(true);
    }
  }, []);

  UpdateTheme();
  return (
    <main>
      {!settingsOpen && <Home openSettings={() => setSettingsOpen(true)} />}
      {settingsOpen && <Settings close={() => setSettingsOpen(false)} />}
    </main>
  );
}

export default App;
