import XMLParser from 'react-xml-parser';

export default async function Fetcher(postalcode, country) {
  let parsedData;
  let url =
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('link='))
      ?.split('=')[1] + postalcode;

  const start = url.indexOf('api/pickuppoint/') + 16; //22
  const toReplace = url.substring(start, start + 2);
  url = url.replace(toReplace, country);

  const uid = document.cookie
    .split('; ')
    .find((row) => row.startsWith('uid='))
    ?.split('=')[1];
  const key = document.cookie
    .split('; ')
    .find((row) => row.startsWith('key='))
    ?.split('=')[1];

  await fetch(url, {
    headers: {
      'X-MyBring-API-Uid': uid,
      'X-MyBring-API-Key': key,
    },
  })
    .then((res) => res.text())
    .then((data) => {
      parsedData = new XMLParser().parseFromString(data);
    })
    .catch((err) => {
      console.log('---error');
      console.log(err);
      parsedData = 'error';
    });

  if (parsedData === 'error' || parsedData.children.length === 1) {
    return 'error';
  } else {
    return parsedData.children;
  }
}
