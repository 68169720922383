import { useEffect } from 'react';
import Capitalize from '../utils/Capitalize';

export default function FirstResponse({ data, postalQuery }) {
  useEffect(() => {
    if (data.length > 0) {
      navigator.clipboard.writeText(data[0].children.find((p) => p.name === 'id').value);

      const el = document.querySelector('.comp_firstres__copy');
      el.style.display = 'block';
    }

    let TIMEOUT = setTimeout(() => {
      const el = document.querySelector('.comp_firstres__copy');
      if (el != null) {
        el.style.display = 'none';
      }
    }, 4000);

    return () => {
      clearTimeout(TIMEOUT);
    };
  }, [data]);

  return (
    <>
      <div className="comp_firstres">
        {data.length > 0 && (
          <>
            <p>{data[0].children.find((p) => p.name === 'id').value}</p>
            <p>{Capitalize(data[0].children.find((p) => p.name === 'name').value.toLowerCase())}</p>
            <p>{data[0].children.find((p) => p.name === 'address').value}</p>
            <p>
              {data[0].children.find((p) => p.name === 'countryCode').value}{' '}
              {data[0].children.find((p) => p.name === 'postalCode').value}{' '}
              {data[0].children.find((p) => p.name === 'city').value}
            </p>
            <p className="comp_firstres__copy">ID has been copied to clipboard.</p>
          </>
        )}
      </div>
    </>
  );
}
