import Capitalize from '../utils/Capitalize';

export default function NewPP({ data, postalQuery }) {
  return (
    <>
      {data.length > 0 && (
        <>
          <h3
            style={{
              marginBottom: '.5rem',
              marginTop: '1.5rem',
              color: 'var(--icon1)',
              fontSize: '1.1rem',
            }}
          >
            Optional pickup points for {postalQuery[0].postalcode}
          </h3>
          <div className="comp_newpp">
            {data.map((pp, i) => (
              <div className="comp_newpp__box" key={pp.children.find((p) => p.name === 'id').value}>
                <div className="comp_newpp__box__nr">{i + 1}</div>
                <p className="comp_newpp--smalltext">
                  ID: {pp.children.find((p) => p.name === 'id').value}
                </p>
                <b>
                  <p>
                    {Capitalize(pp.children.find((p) => p.name === 'name').value.toLowerCase())}
                  </p>
                </b>
                <p>{pp.children.find((p) => p.name === 'address').value}</p>
                <p>
                  {pp.children.find((p) => p.name === 'countryCode').value}{' '}
                  {pp.children.find((p) => p.name === 'postalCode').value}{' '}
                  {pp.children.find((p) => p.name === 'city').value}
                </p>
                <details className="comp_newpp--smalltext">
                  <summary>Opening Hours</summary>
                  {pp.children.find((p) => p.name === 'openingHoursSwedish').value}
                </details>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
