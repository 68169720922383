import { useEffect, useState } from 'react';
import History from '../components/History';
import NewPP from '../components/NewPP';
import Capitalize from '../utils/Capitalize';
import Fetcher from '../utils/Fetcher';
import { AiOutlineSetting } from 'react-icons/ai';
import FirstResponse from '../components/FirstRespose';

export default function Home({ openSettings }) {
  const [postalInput, setPostalInput] = useState('');
  const [country, setCountry] = useState('SE');
  const [errormsg, setErrormsg] = useState('');
  const [ppData, setPpData] = useState([]);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const queries = JSON.parse(localStorage.getItem('queries'));
    if (queries != null) {
      setHistory(queries);
    }

    const cycode = localStorage.getItem('cycode');
    if (cycode != null) {
      setCountry(cycode);
    }
  }, []);

  useEffect(() => {
    const btn = document.querySelector('.view_home__container button');
    btn.disabled = validatePostalInput(postalInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalInput, country]);

  useEffect(() => {
    if (history.length > 6) {
      setHistory((previousArr) => previousArr.slice(0, -1));
    } else if (history.length > 0) {
      localStorage.setItem('queries', JSON.stringify(history));
    }
  }, [history]);

  async function getNewData() {
    const postalInput = document.getElementById('postalcodeinp').value;
    const res = await Fetcher(postalInput, country);
    if (res !== undefined && res !== 'error' && res.length > 0) {
      // console.log(res);
      setPpData(res);
      setHistory((prev) => [
        {
          postalcode:
            res[0].children.find((p) => p.name === 'countryCode').value + ' ' + postalInput,
          id: res[0].children.find((p) => p.name === 'id').value,
          name: Capitalize(res[0].children.find((p) => p.name === 'name').value.toLowerCase()),
        },
        ...prev,
      ]);
      setErrormsg('');
      setPostalInput('');
    } else if (res === 'error') {
      setErrormsg('Error - Check your settings');
      setPpData([]);
    } else {
      setErrormsg(`No results for ${postalInput}`);
      setPpData([]);
    }
  }

  function changeCountyCode(event) {
    const cy = event.target.value;
    localStorage.setItem('cycode', cy);
    setCountry(cy);
  }

  function validatePostalInput(input) {
    if (country === 'SE' && input.length === 5) return false;
    else if (country === 'FI' && input.length === 5) return false;
    else if (country === 'DK' && input.length === 4) return false;
    else if (country === 'NO' && input.length === 4) return false;
    else return true;
  }

  function onInputEnter(event) {
    const inp = document.querySelector('.view_home__container input');
    if (event.code === 'Enter') {
      const invalid = validatePostalInput(inp.value);
      if (!invalid) {
        getNewData();
      }
    }
  }

  return (
    <div className="view_home">
      <div className="view_home__container">
        <h2 style={{ marginBottom: '.3rem' }}>Danis API Helper</h2>
        <div className="view_home__settingsicon" onClick={() => openSettings()}>
          <AiOutlineSetting />
        </div>
        <div className="view_home__container__left">
          <label>
            Postal Code
            <input
              type="number"
              id="postalcodeinp"
              value={postalInput}
              onKeyUp={(e) => onInputEnter(e)}
              onChange={(e) => setPostalInput(() => e.target.value)}
            />
          </label>
          <select id="countryinp" value={country} onChange={(e) => changeCountyCode(e)}>
            <option value="SE">SE</option>
            <option value="FI">FI</option>
            <option value="DK">DK</option>
            <option value="NO">NO</option>
          </select>
          {errormsg && <p className="view_home__error">{errormsg}</p>}
          <button onClick={() => getNewData()}>Get PickupPoint</button>
        </div>
        <FirstResponse data={ppData} postalQuery={history} />
        <History data={history} />
        <NewPP data={ppData} postalQuery={history} />
      </div>
    </div>
  );
}
